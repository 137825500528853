.div {
    background-color: rgba(28, 28, 28, 1);
    box-shadow: 0px 6px 8px rgba(192, 192, 192, 0.5);
    align-self: stretch;
    width: 100%;
    overflow: hidden;
    padding: 19px 51px;
  }
  @media (max-width: 991px) {
    .div {
      max-width: 100%;
      padding: 0 20px;
    }
  }
  .div-2 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .div-2 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 83%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .column {
      width: 100%;
    }
  }
  .div-3 {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 40px 100px;
    color: rgba(192, 192, 192, 0.6);
    justify-content: space-between;
    flex-wrap: wrap;
    font: 700 20px Raleway, sans-serif;
  }
  @media (max-width: 991px) {
    .div-3 {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  .ag-logo-2 {
    align-self: stretch;
    display: flex;
    width: 93px;
    height: 63px;
    margin: auto 0;
  }
  .div-4 {
    align-self: stretch;
    display: flex;
    min-width: 240px;
    align-items: center;
    gap: 40px 70px;
    justify-content: start;
    flex-wrap: wrap;
    margin: auto 0;
  }
  @media (max-width: 991px) {
    .div-4 {
      max-width: 100%;
    }
  }
  .home-page {
    align-self: stretch;
    margin: auto 0;
  }
  .shop-2 {
    color: rgba(192, 192, 192, 1);
    align-self: stretch;
    margin: auto 0;
  }
  .community {
    align-self: stretch;
    margin: auto 0;
  }
  .about-us {
    align-self: stretch;
    margin: auto 0;
  }
  .column-2 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 17%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .column-2 {
      width: 100%;
    }
  }
  .div-5 {
    display: flex;
    width: 100%;
    gap: 15px;
    align-self: stretch;
    color: rgba(28, 28, 28, 1);
    margin: auto 0;
    font: 700 15px Poppins, sans-serif;
  }
  @media (max-width: 991px) {
    .div-5 {
      margin-top: 40px;
    }
  }
  .img {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 49px;
    border-radius: 99px;
  }
  .log-in {
    align-self: stretch;
    border-radius: 20px;
    gap: 10px;
    overflow: hidden;
    padding: 13px 40px;
  }
  @media (max-width: 991px) {
    .log-in {
      padding: 0 20px;
    }
  }